import React, { useContext } from "react"
import { navigate } from 'gatsby'
import Helmet from 'react-helmet'
import Login from '../components/Login'
import UserContext from '../components/context/UserContext'
import OrderForm from '../components/OrderForm'


const Order = () => {

  const { currentuser, loggedin } = useContext(UserContext)

  return (
    <>
      <Helmet>
        <title>Order - Saturday Bagel Club</title>
      </Helmet>
      <OrderForm />
    </>
  )
}

export default Order
